<template>
  <vue-date-picker
      v-model="currentValue"
      @open="changeDate"
      :clearable="false"
      :locale="lang"
      :cancel-text="$t('CANCEL_DATEPICKER')"
      :select-text="$t('SELECT_DATEPICKER')"
      :placeholder="$t('TYPE_DATEPICKER')"
  ></vue-date-picker>
</template>

<script setup lang="ts">
const currentValue = defineModel()
const lang = useRoute().params.lang
const changeDate = () => {
  if (lang == 'vi') {
    setTimeout(() => {
      document
          .querySelectorAll('.dp__calendar_header .dp__calendar_header_item')
          .forEach((item: any, index: number) => {
            if (item.innerText == 'Th') {
              item.innerText = `T${index + 2}`
            }
          })
    }, 100)
  }
}
</script>

<style lang="scss" scoped></style>
